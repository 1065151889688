import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  NumberInput,
  ImageInput,
  ImageField,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const ZipcodeDescriptionCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="cityName" label="Nom de la ville" validate={[required()]} />
      <ArrayInput source="zipcodes" label="Code postaux">
        <SimpleFormIterator>
          <TextInput label="" />
        </SimpleFormIterator>
      </ArrayInput>
      <RichTextInput source="titleHtml" label="Titre" />
      <RichTextInput source="resultHtml" label="Description" />
      <TextInput source="metaTitle" label="Balise meta title" fullWidth validate={[required()]} />
      <TextInput source="metaDescription" label="Balise meta description" fullWidth validate={[required()]} />
      <DateInput source="upcomingPublicationDate" label="Date de publication programmée (si non publiée)" fullWidth />
      <BooleanInput source="published" label="Description publiée" />
      <ArrayInput source="pictureList" label="Liste des images">
        <SimpleFormIterator>
          <ImageInput source="file" label="Fichier de l'image" accept="image/*">
            <ImageField source="path" />
          </ImageInput>
          <TextInput source="altTag" label="Balise alt text" disabled />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)

export default ZipcodeDescriptionCreate
