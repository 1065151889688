export const customer1bisType = {
  OFFER1BIS: 'OFFER1BIS',
  OFFER2BIS: 'OFFER2BIS',
  OFFER3BIS: 'OFFER3BIS',
}

export const customer1bisTypeLabels = {
  [customer1bisType.OFFER1BIS]: 'Offre 1bis',
  [customer1bisType.OFFER2BIS]: 'Offre 2bis',
  [customer1bisType.OFFER3BIS]: 'Offre 3bis',
}
