import React from 'react'
import {
  EmailField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  UrlField,
  BooleanField,
  RichTextField,
  Button,
  useNotify,
  useRefresh,
  ArrayField,
  Datagrid,
  useShowController,
  FunctionField,
} from 'react-admin'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { dateAndTimeWithWeekday } from '../../../helpers/dateRender'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../../config/resources'
import dataProviderHelper from '../../../helpers/dataProvider'
import AvanceImmediateInscriptionFormModalView from '../../avanceimmediate/components/AvanceImmediateInscriptionFormModalView'
import ToggleUseAvanceImmediateButton from '../../avanceimmediate/components/ToggleUseAvanceImmeidateButton'
import AvanceImmediateInscriptionErrorModalView from '../../avanceimmediate/components/AvanceImmediateInscriptionErrorModalView'
import AvanceImmediateInscriptionLinkToOtherInscriptionModalView from '../../avanceimmediate/components/AvanceImmediateInscriptionLinkToOtherInscriptionModalView'
import { paymentReminderModesLabels } from '../../../enums/PaymentReminders'
import { offer3bisModeLabels } from '../../../enums/Offer3bisModes'
import { customer1bisTypeLabels } from '../../../enums/Customer1bisType'

// eslint-disable-next-line react/prop-types
function LemonAccountButton({ record }) {
  const notify = useNotify()
  const refresh = useRefresh()
  const handleCreateLemonway = () => {
    dataProviderHelper
      .post(`/offre1bis/customers/${record.id}/wallet/create`)
      .then((response) => {
        if (response.status === 201) {
          notify('Création du Wallet OK', { type: 'info', messageArgs: { resource: 'post' } })
          refresh()
        } else {
          notify('Erreur lors de la création du Wallet', { type: 'error' })
        }
      })
      .catch((error) => {
        if (error.response) {
          notify('Erreur lors de la création du Wallet', { type: 'error' })
        } else if (error.request) {
          notify('Erreur: Problème réseau', { type: 'error' })
        }
      })
  }

  // eslint-disable-next-line react/prop-types
  if (!record.wallet) {
    return (
      <Button onClick={handleCreateLemonway} label="Créer compte LemonWay">
        <AccountBalanceWalletIcon />
      </Button>
    )
  }
  return <div />
}

function AvanceImmediateLinkMailButton({ record }) {
  const notify = useNotify()
  const refresh = useRefresh()
  const handleSendInvitation = () => {
    dataProviderHelper
      .post(`/avance-immediate/customer1bis/${record.id}/invite`)
      .then((response) => {
        if (response.status === 200) {
          notify("Le mail de formulaire d'inscription avance immédiate a bien été envoyé", {
            type: 'info',
            messageArgs: { resource: 'post' },
          })
          refresh()
        } else {
          notify("Erreur lors de l'envoi du formulaire d'inscription", { type: 'error' })
        }
      })
      .catch((error) => {
        if (error.response) {
          notify("Erreur lors de l'envoi du formulaire d'inscription", { type: 'error' })
        } else if (error.request) {
          notify('Erreur: Problème réseau', { type: 'error' })
        }
      })
  }

  // eslint-disable-next-line react/prop-types
  if (!record.avanceImmediate.informationProvided) {
    return (
      <Button onClick={handleSendInvitation} label="Envoyer un formulaire d'inscription par mail">
        <MailOutlineIcon />
      </Button>
    )
  }
  return <div />
}

const Offre1bisCustomerShow = (props) => {
  const { record } = useShowController(props)
  const infoProvided = record && record.avanceImmediate && record.avanceImmediate.informationProvided === true
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informations personnelles">
          <TextField source="id" label="Identifiant" />
          <TextField source="sageId" label="SAGE ID" />
          <TextField source="last_name" label="Nom" />
          <TextField source="first_name" label="Prénom" />
          <DateField locales="fr-FR" source="createdDate" options={dateAndTimeWithWeekday} label="Date de création" />
          <EmailField source="email_address" label="Adresse mail" />
          <TextField source="street_address" label="N° et rue" />
          <TextField source="zip_code" label="Code postal" />
          <TextField source="city" label="Ville" />
          <TextField source="phone_number" label="Téléphone" />
          <RichTextField source="comment" label="Commentaires" />
          <FunctionField label="Type de client" render={(r) => customer1bisTypeLabels[r.type]} />
          <BooleanField source="invoice_by_letter" label="Envoi de facture par lettre" />
          <ReferenceField
            label="Vendeur"
            link="show"
            source="professionalProfileEntityRelationId"
            reference={RESOURCE_IDENTIFIERS.PROFESSIONAL}
          >
            <TextField source="profile.fullname" />
          </ReferenceField>
        </Tab>
        <Tab label="Wallet">
          <LemonAccountButton />
          <ReferenceField label="Identifiant" link="show" source="wallet.id" reference="wallets">
            <TextField source="idProviderReference" label="Identifiant ATJ (id externe)" />
          </ReferenceField>
          <TextField source="wallet.idProviderTechnical" label="Identifiant Lemonway" />
          <UrlField source="wallet.lemonwayUrl" label="Lien lemonway" target="_blank" />
          <TextField source="wallet.status" label="Statut" />
          <NumberField source="wallet.balance" label="Solde" options={{ style: 'currency', currency: 'EUR' }} />
          <BooleanField source="wallet.active" label="Actif" />
        </Tab>
        <Tab label="Avance immédiate">
          <BooleanField label="Informations envoyées" source="avanceImmediate.informationProvided" />
          <AvanceImmediateInscriptionFormModalView customer1bisId={props.id} />
          <AvanceImmediateInscriptionErrorModalView />
          <AvanceImmediateInscriptionLinkToOtherInscriptionModalView customer1bisId={props.id} />
          {infoProvided && (
            <BooleanField source="avanceImmediate.status.ready" label="Avance immédiate prête à utilisation" />
          )}
          {infoProvided && <TextField source="avanceImmediate.customerEmail" label="Email URSSAF" />}
          {infoProvided && (
            <TextField source="avanceImmediate.status.description" label="Description du statut par l'URSSAF" />
          )}
          {!infoProvided && (
            <ArrayField source="avanceImmediate.inscriptionLinks" label="Liens envoyés par mail">
              <Datagrid>
                <UrlField source="url" label="Lien" />
                <DateField
                  locales="fr-FR"
                  source="expirationDateTime"
                  options={dateAndTimeWithWeekday}
                  label="Date d'expiration"
                />
              </Datagrid>
            </ArrayField>
          )}
          <AvanceImmediateLinkMailButton />
          <ToggleUseAvanceImmediateButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default Offre1bisCustomerShow
