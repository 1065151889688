import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import MultipleChips from '../../components/multipleChips/MutlipleChips'

const ZipcodeDescriptionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="cityName" label="Nom de la ville" />
      <MultipleChips source="zipcodes" title="Code postaux" />
      <RichTextField source="titleHtml" label="Titre" />
      <RichTextField source="resultHtml" label="Description" />
      <TextField source="metaTitle" label="Balise meta title" />
      <TextField source="metaDescription" label="Balise meta description" />
      <DateField source="upcomingPublicationDate" label="Date de publication programmée (si non publiée)" />
      <BooleanField source="published" label="Description publiée" />
      <ArrayField source="pictureList" label="Liste des images">
        <Datagrid>
          <ImageField source="file.path" label="Image" />
          <TextField source="altTag" label="Balise alt text" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)

export default ZipcodeDescriptionShow
